<template>
  <div id="PrivilegioContainer" >

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">
        Privilégios de Acesso
      </p>
      <p class="mt-n4 ml-3 font-weight-light body-1" style="color:#A0A0A0">
        Abaixo selecione o usuário que deseja atribuir os privilégios de acessos e use as funcionalidades para permitir ou 
        restringir seus acessos
      </p>      
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- USUARIOS ------------------------------------------>
    <div class="flex-container mt-n4">
              <v-autocomplete
                :items="usuarios"
                v-model="usuario_selecionado"
                item-text="nome"
                item-value="cod_usuario"
                label="Usuário"
                background-color="#FFFFFF"
                filled
                return-object
                required
                outlined
                dense
                style="max-width:500px;"
            >
            <template v-slot:selection="data">
              <v-avatar color="#C9C9C9" class="mt-1 mr-3">
                <v-avatar>
                  <v-img
                    v-if="usuario_selecionado.foto"
                    :src="usuario_selecionado.foto">
                  </v-img>
                  <span 
                    v-else
                    class="iniciais">
                    {{ nomeIniciais(usuario_selecionado.nome) }}
                  </span>
                </v-avatar>
              </v-avatar>
              {{ data.item.nome }}
            </template>

              <template v-slot:item="data">
                <template>
                  <v-avatar color="#C9C9C9" class="mt-1 mr-3">
                <v-avatar>
                  <v-img
                    v-if="data.item.foto"
                    :src="data.item.foto">
                  </v-img>
                  <span 
                    v-else
                    class="iniciais">
                    {{ nomeIniciais(data.item.nome) }}
                  </span>
                </v-avatar>
              </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </div>
              <!-- :disabled="lb_SomenteCorretor" -->

  <!-- PRIVILEGIOS ------------------------------------------>
  <div v-if="usuario_selecionado && usuario_selecionado.cod_pessoa">
    <v-tabs
      id="tabs"
      v-model="tab"
      background-color="transparent"
      slider-size="3"
      slider-top="10"
      tabindex="-1"
      active-class="active"
      class="mt-n3"
    >
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        v-bind:style="{ 'background-color': tab == 0 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"
      > 
        <p class="font-primary body-1 mt-2" v-bind:style="{ 'color': tab == 0 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Aplicações
        </p>
      </v-tab>
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        v-bind:style="{ 'background-color': tab == 1 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"
      >
        <p class="font-primary body-1 mt-2" v-bind:style="{ 'color': tab == 1 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Empreendimentos
        </p>
      </v-tab>
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        v-bind:style="{ 'background-color': tab == 2 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"
      >
        <p class="font-primary body-1 mt-2" v-bind:style="{ 'color': tab == 2 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Painéis (Dashboards)
        </p>
      </v-tab>
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        v-bind:style="{ 'background-color': tab == 3 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"
      >
        <p class="font-primary body-1 mt-2" v-bind:style="{ 'color': tab == 3 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Relatórios
        </p>
      </v-tab>
    </v-tabs>

    <v-row class="mx-0">
      <div v-if="tab == 0" style="width: 100%;">
        <PrivilegioUsuarioAplicacao
          :_cod_pessoa.sync="usuario_selecionado.cod_pessoa"
          class="mt-9"
          style="width:100%"
        />
      </div>
      <div v-if="tab == 1" style="width: 100%;">
        <PrivilegioUsuarioEmpreendimento
          :_cod_pessoa.sync="usuario_selecionado.cod_pessoa"
          class="mt-9"
          style="width:100%"
        />
      </div>
      <div v-if="tab == 2" style="width: 100%;">
        <PrivilegioUsuarioPainel
          :_cod_pessoa.sync="usuario_selecionado.cod_pessoa"
          class="mt-9"
          style="width:100%"
        />
      </div>
      <div v-if="tab == 3" style="width: 100%;">
        <PrivilegioUsuarioRelatorio
          :_cod_pessoa.sync="usuario_selecionado.cod_pessoa"
          class="mt-9"
          style="width:100%"
        />
      </div>
    </v-row>
  </div>

  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BACKGROUND_2 } from "../../services/constantes";
import { nomeIniciais } from "../../services/funcoes";
import PrivilegioUsuarioAplicacao from "../CadastroBasico/Privilegios/PrivilegioUsuarioAplicacao.vue";
import PrivilegioUsuarioEmpreendimento from "../CadastroBasico/Privilegios/PrivilegioUsuarioEmpreendimento.vue";
import PrivilegioUsuarioPainel from "../CadastroBasico/Privilegios/PrivilegioUsuarioPainel.vue";
import PrivilegioUsuarioRelatorio from "../CadastroBasico/Privilegios/PrivilegioUsuarioRelatorio.vue";
import store_Pessoa from "../Pessoas/store_Pessoa"
import store_Usuarios from "../Usuarios/store_Usuarios"

export default {

  name : "PrivilegioContainer",

  props: ['IsMobile'],

  components: {
    PrivilegioUsuarioAplicacao,
    PrivilegioUsuarioEmpreendimento,
    PrivilegioUsuarioPainel,
    PrivilegioUsuarioRelatorio
  },

  data() {
    return { 
      store_Pessoa      : store_Pessoa,
      store_Usuarios    : store_Usuarios,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
      COR_BACKGROUND_2  : COR_BACKGROUND_2,

      nomeIniciais      : nomeIniciais,
      expand            : true,
      tab               : 0,
      itemSelecionado   : 0,

      abas_mobile: [
        'Dados Básicos', 'Documentos',
      ],

      usuarios              : [],
      usuario_selecionado   : {},
      cod_pessoa            : null,
      filtro                : null,

    }
  },

  created() {
    // console.log('CREATED')
  },

  destroyed() {
    // console.log('DESTROYED')
  },

  async mounted() {
    this.busca_Usuarios();
  },

  computed: {
    isMobile() {
      // console.log("IsMobile", this.IsMobile);
      if (this.IsMobile)
        return this.IsMobile
      else  
        return this.$vuetify.breakpoint.name === "xs";
    },
  },

  methods : {
    async busca_Usuarios() {
      let lo_params = {};
      lo_params.limit = 9999999999;
      if (this.filtro) {
        lo_params.filtro = this.filtro;
      }
      await this.store_Usuarios.UsuariosGet(lo_params);
      //console.log(this.store_Usuarios.dados);
      this.usuarios = this.store_Usuarios.dados.map((data) => {
                                                        if (data.cod_pessoa)
                                                          return data
                                                        })
      //console.log(this.usuarios);
    },

  }

};
</script>

<style scoped lang="scss">
#PrivilegioContainer {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #PrivilegioContainer {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PrivilegioContainer::-webkit-scrollbar {
  width: 5px;
}

#PrivilegioContainer::-webkit-scrollbar-button {
  padding: 1px;
}

#PrivilegioContainer::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PrivilegioContainer::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PrivilegioContainer::-webkit-scrollbar {
    width: initial;
  }

  #PrivilegioContainer::-webkit-scrollbar-button {
    padding: initial;
  }

  #PrivilegioContainer::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PrivilegioContainer::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}
// -------------------------------------
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}
.v-application--wrap{
  min-height: 107vh;
}

.v-tab{
  width: auto!important;
  padding-left: 40px;
  padding-right: 40px;
}
.v-tabs{
  display: flex;
  justify-content: left;
}

@media(max-width: 599px) {
  .title-page {
    width: 100%!important;
  }

  .text-opacity {
    opacity: 0.7;
  }
}

@media(max-width: 375px) {
  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100%;
  height: calc(100vh - 150px)!important;
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 130px)!important;
    margin-top: -4px!important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  height: 100%!important;
  border-radius: 8px;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA)!important;
}

.card-content {
  height: 100%!important;
}

.wrapper-top {
  height: 105px!important;
}

.header-text {
  font-family: "Open Sans", sans-serif!important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 15px)!important;
}

@media(max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column!important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor-vertical {
    display: none;
  }

}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px!important;
  height: 100%;
  position: relative;
  background-color: var(--COR_BACKGROUND);
  // background-color: #FCFCFC !important;
  margin-top: 0px;
  padding-top: 2px!important;
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px!important;
    padding-left: 0px!important;
    width: 100%;
  }
}


.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}

.bg-active {
  // background-color: var(--COR_PRINCIPAL);
  background-color: #efeff3;
  // color : #0000FF !important;
}

.cabecalho {
  background-color: var(--COR_BACKGROUND) !important;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px!important;
  }
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif!important;
}
.active {
  color: blue;
}

.flex-container {
    display: flex;
    justify-content: left;
    align-items: center;
  }

</style>
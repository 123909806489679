<template>
  <div id="Configuracoes">

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="{ name: '' }"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link> 
        Configurações
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->

      <!-- <div
        class="d-flex d-sm-none mt-n14 mb-6 pa-4 pt-5">
        <v-avatar tile color="grey darken" size="72" class="rounded mr-3">
          <v-avatar
          class="rounded"
          color="grey darken"
          size="70"
          tile>
            <v-img src="http://192.168.100.58:3003/imagens/imoveis/fotos/empresa_1/005509/005509_000001.png"></v-img>
          </v-avatar>
        </v-avatar>

        <div class="d-flex flex-column align-center mt-n1">
          <div class="d-flex flex-column">
            <span class="body-2 font-weight-medium text-white">{{ dados.nome }}</span>
            <span class="text-opacity body-2 text-white">{{ dados.email }}</span>
            <span class="text-opacity body-2 text-white">{{ dados.telefone }}</span>
          </div>

        </div>
      </div> -->

      <!-- TABS MOBILE -->
      <div class="d-block d-sm-none mt-n6 mb-2" tabindex="-1">
        <v-tabs
          v-if="isMobile"
          v-model="tab"
          background-color="transparent"
          dark
          class="tab-mobile mr-2 ml-2 mt-n3 pa-0"
          center-active
          slider-size="2"
          tabindex="-1"
          slider-color="#D0D0D0"
          active-class="active">
          <v-tab
            v-for="item in abas_mobile"
            :key="item"
            class="text-none"
            tabindex="-1">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <!-- FIM TABS MOBILE -->
    </v-toolbar-title>

    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card class="card d-flex flex-column pb-0" color="">
        <v-card-text class="card-content my-0 py-0 px-2">

          <!-- COLUNA TOPO ------------------------------->
          <!-- <v-row class="d-none d-sm-block wrapper-top rounded-tl-lg my-0 py-0 px-1">
             <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet
              class="cabecalho d-flex rounded-t-lg mx-auto pa-4 pb-4"
              >
                <v-avatar tile color="grey darken" size="72" class="avatar rounded mr-3">
                  <v-avatar
                  class="rounded"
                  color="grey darken"
                  size="70"
                  tile>
                    <v-img src="http://192.168.100.58:3003/imagens/imoveis/fotos/empresa_1/005509/005509_000001.png"></v-img>
                  </v-avatar>
                </v-avatar>

                <v-col class="pa-0 pl-1">
                  <div class="d-flex flex-column">
                    <span class="header-text body-2 font-weight-medium mr-4">Nome: <span class="font-weight-bold body-1" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.nome }}</span></span>
                    <span class="header-text mr-4">E-mail: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.email }}</span></span>
                    <span class="header-text mr-4">Telefone: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.telefone }}</span></span>
                  </div>
                </v-col>
              </v-sheet>

              <v-divider class="divisor" style="width: 100%"></v-divider>

            </v-col>
          </v-row> -->
          <!-- FIM COLUNA TOPO ------------------------------->

          <v-row class="wrapper-bottom my-0 py-0 px-1" style="height: 100% !important;">
            <!-- COLUNA ESQUERDA ------------------------------->
            <v-col class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0" style="background: #F7F7F7;">
              <v-list
                v-show="expand"
                class="list rounded-bl-lg overflow-y-scroll pt-0" dense>

                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  color="primary"
                >
                  <template v-for="[index, icon, text] in links"
                    link
                    class="px-3">

                    <!-- SEPARADOR ------------------------------->
                    <v-divider 
                      v-if="text == 'separador'"
                      :key="index"
                      class="my-1"
                    ></v-divider>
                    <!-- FIM SEPARADOR ------------------------------->

                    <v-list-item v-else
                      :key="index"
                      active-class="bg-active"
                      :class="index == itemSelecionado ? 'bg-active' : ''"
                    >
                      <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                        <v-icon
                          class="size-icon"
                          active-class="bg-active-icon"
                          :class="index == itemSelecionado ? 'bg-active-icon' : ''"
                        >{{ icon }}</v-icon>
                      </v-list-item-icon>
                      
                      <v-list-item-content v-if="text != 'separador'" class="mr-n2">
                        <v-list-item-title 
                          :value="index == itemSelecionado"
                          >{{ text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>  
              </v-list>    

              <v-btn
              class="btn-expanded ma-0 pa-0"
              @click="expand = !expand"
              >
                <v-icon
                v-if="expand == false"
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-right</v-icon>

                <v-icon
                v-else 
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-left</v-icon>
              </v-btn>  

            </v-col>
            <!-- FIM COLUNA ESQUERDA ------------------------------->

            <v-divider vertical class="divisor" style=" width: 50px"></v-divider>

            <!-- COLUNA DIREITA ------------------------------->
            <v-col v-if="itemSelecionado == 1" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
              <MinhaEmpresa />  
            </v-col>
            <v-col v-if="itemSelecionado == 2" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
              <PrivilegioContainer />  
            </v-col>
            <!-- FIM COLUNA DIREITA ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../services/constantes";
import { CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";
import MinhaEmpresa from "./MinhaEmpresa.vue";
import PrivilegioContainer from "./PrivilegioContainer.vue";

export default {
  components: {
    MinhaEmpresa,
    PrivilegioContainer
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      expand: true,
      tab: null,
      itemSelecionado: 0, 
      
      dados: {
        nome: "Fernando Garcia",
        telefone: "(16) 99999-9999",
        email: "john@vuetifyjs.com",
      },

      links: [
        [0, 'mdi-view-dashboard-outline', 'Visão Geral'],
        [1, 'mdi-account-outline', 'Minha Empresa'],
        [2, 'mdi-lock-open-outline', 'Privilégios e Permissões'],
        [3, 'mdi-help-circle-outline', 'Ajuda e Suporte'],
      ],

      abas_mobile: [
        'Visão Geral',
        'Minha Empresa',
        'Privilégios e Permissões',
        'Ajuda e Suporte',
      ],

      responsavel: [
      'Fernando Garcia',
      'Bruno Duarte',
      'João Feliciano',
      ],

        dialog_crop           : false,
        valid                 : true,
        alert                 : false,
        alert_msg             : "",
        foto                  : null,
        nome                  : null,
        cpf                   : null,
        email                 : null,
        telefone              : "",
        celular               : "",


        cep                   : "",
        logradouro            : null,
        numero                : "",
        complemento           : null,
        bairro                : null,
        uf                    : null,
        cidade                : null,

        observacao            : null,


        form: false,
        
      nameRules : [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      emailRules: [
        (value) => !!value || "O e-mail é obrigatório",
        (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
      ],
      foneRules: [
        (value) => !!value || "O telefone é obrigatório",
        (value) => (value && telefoneIsValid(value)) || "O telefone não é válido",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
      ],

      logradouroRules : [
        (value) => !!value || "O logradouro é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O logradouro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O logradouro deve ter menos do que 60 caracteres",
      ],

      numeroRules : [
        (value) => !!value || "O número é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O número deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O número deve ter menos do que 60 caracteres",
      ],

      complementoRules : [
        (value) => !!value || "O complemento é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O complemento deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O complemento deve ter menos do que 60 caracteres",
      ],

      bairroRules : [
        (value) => !!value || "O bairro é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O bairro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O bairro deve ter menos do que 60 caracteres",
      ],

      cidadeRules : [
        (value) => !!value || "O cidade é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O cidade deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O cidade deve ter menos do que 60 caracteres",
      ],
          };
  },

  created() {
    if (this.$route.query.aba)
      this.itemSelecionado = this.$route.query.aba;
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  watch: {
    "$route": function (val) {
      this.itemSelecionado = val.query.aba;
    },

  //   cpf(value) {
  //       this.MASK_CPFCNPJ = maskCPFCNPJ(value);
  //   },
  //   celular(value) {
  //       this.MASK_TELEFONE = maskTELEFONE(value);
  //   }
    email: function (val) {
      this.email = val.toLowerCase();
    },

    // Esta rotina serve para verificar se clicou em outra rota para esta aplicacao:
    // Ex: Para abrir a aba : Minha Empresa deve-se passar 1 no parametro "query" do router que está configurado no
    // consts : title: "Minha Empresa", path: "/configuracoes?aba=1"....
    // 

  },

  methods: {
    fecha_alert() {
      this.alert = false;
  },

    validate () {
      this.$refs.form.validate()
    },

    reset () {
      this.$refs.form.reset()
    },

    resetValidation () {
      this.$refs.form.resetValidation()
    },

  },
};
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}

@media(max-width: 599px) {
  .title-page {
    width: 100%!important;
  }

  .text-opacity {
    opacity: 0.7;
  }

  .tab-mobile {
    margin-left: -44px!important;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100%;
  height: calc(100vh - 110px)!important;
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px)!important;
    margin-top: -4px!important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  height: 100%!important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA)!important;
}

.card-content {
  height: 100%!important;
}

.wrapper-top {
  height: 105px!important;
}

.header-text {
  font-family: "Open Sans", sans-serif!important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px)!important;
}

.divisor {
  height: 100%;
}

@media(max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column!important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px!important;
  height: 100%;
  position: relative;
  background-color: #F7F7F7;
  margin-top: 0px;
  padding-top: 2px!important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
  max-width: 100%;
  // overflow-y: scroll;
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px!important;
    padding-left: 0px!important;
    width: 100%;
  }
}

.list {
  background-color: transparent;
  max-width: 300px!important;
  min-width: 220px!important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL);
  color : white !important;
}

.bg-active-icon {
  background-color: transparent;
  color : white !important;
}

.cabecalho {
  background-color: #F7F7F7;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px!important;
  }
}
</style>
<template>
  <div id="MinhaEmpresa">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">
        Minha Empresa
      </p>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- ARQUIVO/WEBCAM DIALOG ------------------------------------------ -->
    <v-dialog
      v-if="dialog_crop"
      :value="true"
      persistent
      style="width: 330"
      :max-width="330"
      :retain-focus="false"
    >
      <v-card>
        <v-card-text class="pt-7">
          <VueCropImage
            :img="dados.foto"
            :imgsync.sync="dados.foto"
            :dialog_crop.sync="dialog_crop"
            ref="VueCropImage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- FIM ARQUIVO/WEBCAM DIALOG ----------------------------------------------------->

    <!-------- FORM ----------------------------->
    <v-form
    v-model="valid"
    ref="form"
    class="mx-1"
    >
      <!-- PRIMEIRA ROW ------------------------>
      <v-row>
        <!-- PRIMEIRA COLUNA / AVATAR ------------------------>
        <v-col cols="12" sm="4" class="col-avatar py-0 pt-2 pl-2">
          <div class="d-flex justify-center mt-n2">
            <v-avatar
              v-if="!dados.foto"
              size="100px"
              v-ripple
              class="avatar grey lighten-2 mb-n10"
            >
              <span v-on:click="dialog_crop = true"
                >Clique p/ adicionar avatar
              </span>
            </v-avatar>

            <v-avatar v-ripple v-else size="100px" class="avatar mb-3">
              <img
                :src="dados.foto"
                alt="avatar"
                v-on:click="dialog_crop = true"
              />
            </v-avatar>
          </div>
        </v-col>
        <!-- FIM PRIMEIRA COLUNA / AVATAR ------------------------>

        <!-- SEGUNDA COLUNA / NOME ------------------------>
        <v-col cols="12" sm="8" class="pt-2 px-2 pb-0">
          <v-text-field
          v-model="dados.nome"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Nome"
          placeholder="Nome"
          filled
          outlined
          required
          dense
          :rules="nomeRules"
          ></v-text-field>
        </v-col>
        <!-- FIM SEGUNDA COLUNA / NOME ------------------------>
      </v-row>
      <!-- PRIMEIRA ROW ------------------------>
      
      <!-- SEGUNDA ROW ------------------------>
      <v-row class="segunda-row">
        <v-col sm="4" class=" pt-0 px-2">
        </v-col>

        <v-col cols="12" sm="4" class=" pt-0 px-2">
          <v-text-field
          v-model="dados.cnpj"
          v-mask="MASK_CPFCNPJ"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Cnpj"
          placeholder="Cnpj"
          filled
          outlined
          required
          dense
          :rules="cnpjRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.telefone"
          v-mask="MASK_TELEFONE"
          class="mt-n2 mb-0"
          background-color="#FFF"
          label="Telefone"
          placeholder="Telefone"
          filled
          outlined
          dense
          :rules="foneRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- FIM SEGUNDA ROW ------------------------> 

      <!-- TERCEIRA ROW ------------------------>
      <v-row class="terceira-row mt-n2 mb-1">
        <v-col cols="12" sm="3" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.celular"
          v-mask="MASK_TELEFONE"
          class="mt-n2"
          background-color="#FFF"
          label="Celular"
          placeholder="Celular"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
        <v-text-field
          v-model="dados.email"
          class="mt-n2"
          background-color="#FFF"
          label="E-mail"
          placeholder="E-mail"
          filled
          outlined
          dense
          :rules="emailRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-select
          label="Responsável pela empresa"
          v-model="dados.situacao"
          class="mt-n2"
          filled
          outlined
          dense
          background-color="#FFF"
          :items="responsavel"
          :rules="responsavelRules"
          ></v-select>
        </v-col>
      </v-row>
      <!-- FIM TERCEIRA ROW ------------------------>

      <!-- QUARTA ROW ------------------------>
      <v-row class="mt-n1 mb-1">
        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.cep"
          class=" mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Cep"
          placeholder="Cep"
          filled
          outlined
          required
          dense
          :rules="cepRules"
        ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.logradouro"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Logradouro"
          placeholder="Logradouro"
          filled
          outlined
          required
          dense
          :rules="logradouroRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.numero"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Número"
          placeholder="Número"
          filled
          outlined
          dense
          :rules="numeroRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- FIM QUARTA ROW ------------------------>

      <!-- QUINTA ROW ------------------------>
      <v-row class="mt-n1 mb-1">
        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.complemento"
          class=" mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Complemento"
          placeholder="Complemento"
          filled
          outlined
          required
          dense
          height="35px"
        ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.bairro"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Bairro"
          placeholder="Bairro"
          filled
          outlined
          required
          dense
          :rules="bairroRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.uf"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Uf"
          placeholder="Uf"
          filled
          outlined
          dense
          :rules="ufRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- FIM QUINTA ROW ------------------------>

      <!-- SEXTA ROW ------------------------>
      <v-row class="mt-n1 mb-1">
        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-select
          label="Cidade"
          v-model="dados.cidade"
          class="mt-n2"
          filled
          outlined
          dense
          required
          background-color="#FFF"
          :items="endereco_cidade"
          :rules="cidadeRules"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="1" md="2" lg="4" class="py-0 px-2">
          <v-text-field
          v-model="dados.observacoes"
          class=" mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Observações"
          placeholder="Observações"
          filled
          outlined
          required
          dense
          height="35px"
        ></v-text-field>
        </v-col>
      </v-row>
      <!-- FIM SEXTA ROW ------------------------>

    </v-form>
    <!-- FIM FORM -->    

    <v-divider class="mt-4 d-none"></v-divider>

    <v-footer color="transparent" elevation="0" class="footer justify-center mt-3 pt-3">
      <router-link
      :to="{ name: 'PessoaConsulta' }"
      class="nav-link"
      aria-current="page"
      >
        <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>
      </router-link>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>

    <v-alert
    :value="alert"
    v-on:click="fecha_alert()"
    color="red"
    dark
    icon="mdi-home"
    transition="scale-transition"
    elevation="1"
    type="warning"
    dense
    >
      {{ alert_msg }}
    </v-alert>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>    

  </div>
</template>

<script>
import { CpfCnpjIsValid, telefoneIsValid } from "../../services/funcoes";
import { COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, arrayUF } from "../../services/constantes";
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";

export default {
  name: "MinhaEmpresa",

  components: {
    VueCropImage,
  },

  data() {
    return {

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      COR_SUBTITULO     : COR_SUBTITULO, 
      arrayUF           : arrayUF, 
      
      valid: true,
      alert: false,
      alert_msg: "",
      dialog_crop: false,

      /* Formulário Reprovação */
      snack: false,
      snackColor: "",
      snackText: "",
      form: false,

      dados: {
        foto: null,                   
        nome: "",                 
        cpf: "",                    
        email: "",                  
        telefone: "",             
        celular: "",                

        cep: "",                 
        logradouro: "",              
        numero: "",             
        complemento: "",            
        bairro: "",                 
        uf: "",                      
        cidade: "",                 

        observacoes: "",            
      },

      /* SELECT RESPONSAVEL */
      responsavel: [
        "Fernando Garcia", "Bruno Duarte", "João Feliciano",
      ],

      /* CIDADE */
      endereco_cidade: [
      'Franca',
      'Claraval',
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      foneRules: [
        (value) => !!value || "O telefone é obrigatório",
        (value) =>
          (value && telefoneIsValid(value)) || "O telefone não é válido",
      ],
      celRules: [
        (value) => !!value || "O celular é obrigatório",
        (value) =>
          (value && telefoneIsValid(value)) || "O celular não é válido",
      ],
      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CNPJ não é válido",
        (value) =>
          (value && value.length > 14) ||
          "O CNPJ deve ter 14 caracteres",
      ],
      emailRules: [
        (value) => !!value || "O e-mail é obrigatório",
        (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
      ],
      responsavelRules: [
        (value) => !!value || "O responsável é obrigatório",
      ],
      logradouroRules: [
        (value) => !!value || "O logradouro é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O logradouro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O logradouro deve ter menos do que 60 caracteres",
      ],
      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      bairroRules: [
        (value) => !!value || "O bairro é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O bairro deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O bairro deve ter menos do que 60 caracteres",
      ],
      cepRules: [
        (value) => !!value || "O cep é obrigatório",
        (value) => /^[0-9]{2}[0-9]{3}-[0-9]{3}$/.test(value) || "O cep não é válido",
      ],
      cidadeRules: [
        (value) => !!value || "A cidade é obrigatório",
      ],
      ufRules: [
        (value) => !!value || "O UF é obrigatório",
      ],
    };
  },

  methods: {
    fecha_alert() {
      this.alert = false;
    },

    validate () {
      this.$refs.form.validate()
    },
  },
};
</script>

<style scoped>
#MinhaEmpresa {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #MinhaEmpresa {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#MinhaEmpresa::-webkit-scrollbar {
  width: 5px;
}

#MinhaEmpresa::-webkit-scrollbar-button {
  padding: 1px;
}

#MinhaEmpresa::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#MinhaEmpresa::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #MinhaEmpresa::-webkit-scrollbar {
    width: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-button {
    padding: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #MinhaEmpresa::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

@media(max-width: 599px) {
  .col-avatar {
    margin-bottom: 56px;
  }

  .segunda-row div:nth-child(2) {
    margin-top: -12px;
  }

  .segunda-row div:nth-child(3) {
    margin-top: -12px;
  }

  .terceira-row {
    margin-top: 12px!important;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  color: #FF0000;
}
</style>
